.lead {
  font-size: 3.25rem;
}

p:last-child {
  margin-bottom: 0;
}

ul, ol{
  margin-bottom: 1.5rem;
  padding-left: 20px;
  list-style-type: none;

  li {
    position: relative;
    margin-bottom: .8rem;

    &:last-child {
      margin-bottom: 0;
    }

    ul, ol {
      margin-top: .5rem;
    }
  }
}

ul {
  li {
    padding-left: 20px;
    margin-bottom: .8rem;

    &:before {
      content: '\2014';
      color: var(--dark-brand-color);
      position: absolute;
      left: 0;
      top: -1px;
    }
  }
}

ol {
  counter-reset: item;

  li {
    padding-left: 20px;
    margin-bottom: .8rem;

    &:before {
      counter-increment: item;
      content: counter(item)'.';
      color: var(--dark-brand-color);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

