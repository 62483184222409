.status {
  width: 18px;
  height: 18px;
  background: #4c804b;
  border-radius: 100px;

  &--1 {
    background: #ff4148;
  }

  &--2 {
    background: #b5b5b5;
  }
}
