// Script-generated file, do not modify by hand

$icon-agency-content: '\f101';
$icon-arrow-left-content: '\f102';
$icon-arrow-long-content: '\f103';
$icon-close-thin-content: '\f104';
$icon-close-content: '\f105';
$icon-copy-content: '\f106';
$icon-department-content: '\f107';
$icon-document-text-content: '\f108';
$icon-drag-content: '\f109';
$icon-edit-content: '\f10a';
$icon-empty-user-content: '\f10b';
$icon-expand-content: '\f10c';
$icon-eye-off-content: '\f10d';
$icon-eye-content: '\f10e';
$icon-file-text-content: '\f10f';
$icon-info-content: '\f110';
$icon-list-view-content: '\f111';
$icon-lnr-bubble-fill-content: '\f112';
$icon-lnr-bubble-content: '\f113';
$icon-lnr-camera-content: '\f114';
$icon-lnr-cart-content: '\f115';
$icon-lnr-database-content: '\f116';
$icon-lnr-download-content: '\f117';
$icon-lnr-exit-content: '\f118';
$icon-lnr-file-empty-content: '\f119';
$icon-lnr-funnel-content: '\f11a';
$icon-lnr-history-content: '\f11b';
$icon-lnr-layers-content: '\f11c';
$icon-lnr-location-content: '\f11d';
$icon-lnr-menu-circle-content: '\f11e';
$icon-lnr-minus-circle-content: '\f11f';
$icon-lnr-paperclip-content: '\f120';
$icon-lnr-pencil-content: '\f121';
$icon-lnr-plus-circle-content: '\f122';
$icon-lnr-printer-content: '\f123';
$icon-lnr-question-circle-content: '\f124';
$icon-lnr-smartphone-content: '\f125';
$icon-lnr-sync-content: '\f126';
$icon-lnr-upload-content: '\f127';
$icon-lnr-user-content: '\f128';
$icon-lnr-warning-content: '\f129';
$icon-loop-content: '\f12a';
$icon-map-view-content: '\f12b';
$icon-menu-burger-content: '\f12c';
$icon-plus-content: '\f12d';
$icon-qrcode-content: '\f12e';
$icon-refund-ticket-content: '\f12f';
$icon-scheme-content: '\f130';
$icon-terminal-content: '\f131';
$icon-ticket-content: '\f132';
$icon-trash-content: '\f133';
$icon-upload-card-content: '\f134';
$icon-xlsx-file-content: '\f135';

@mixin icon-classes {
  .icon-agency:before {
    content: $icon-agency-content;
  }
  .icon-arrow-left:before {
    content: $icon-arrow-left-content;
  }
  .icon-arrow-long:before {
    content: $icon-arrow-long-content;
  }
  .icon-close-thin:before {
    content: $icon-close-thin-content;
  }
  .icon-close:before {
    content: $icon-close-content;
  }
  .icon-copy:before {
    content: $icon-copy-content;
  }
  .icon-department:before {
    content: $icon-department-content;
  }
  .icon-document-text:before {
    content: $icon-document-text-content;
  }
  .icon-drag:before {
    content: $icon-drag-content;
  }
  .icon-edit:before {
    content: $icon-edit-content;
  }
  .icon-empty-user:before {
    content: $icon-empty-user-content;
  }
  .icon-expand:before {
    content: $icon-expand-content;
  }
  .icon-eye-off:before {
    content: $icon-eye-off-content;
  }
  .icon-eye:before {
    content: $icon-eye-content;
  }
  .icon-file-text:before {
    content: $icon-file-text-content;
  }
  .icon-info:before {
    content: $icon-info-content;
  }
  .icon-list-view:before {
    content: $icon-list-view-content;
  }
  .icon-lnr-bubble-fill:before {
    content: $icon-lnr-bubble-fill-content;
  }
  .icon-lnr-bubble:before {
    content: $icon-lnr-bubble-content;
  }
  .icon-lnr-camera:before {
    content: $icon-lnr-camera-content;
  }
  .icon-lnr-cart:before {
    content: $icon-lnr-cart-content;
  }
  .icon-lnr-database:before {
    content: $icon-lnr-database-content;
  }
  .icon-lnr-download:before {
    content: $icon-lnr-download-content;
  }
  .icon-lnr-exit:before {
    content: $icon-lnr-exit-content;
  }
  .icon-lnr-file-empty:before {
    content: $icon-lnr-file-empty-content;
  }
  .icon-lnr-funnel:before {
    content: $icon-lnr-funnel-content;
  }
  .icon-lnr-history:before {
    content: $icon-lnr-history-content;
  }
  .icon-lnr-layers:before {
    content: $icon-lnr-layers-content;
  }
  .icon-lnr-location:before {
    content: $icon-lnr-location-content;
  }
  .icon-lnr-menu-circle:before {
    content: $icon-lnr-menu-circle-content;
  }
  .icon-lnr-minus-circle:before {
    content: $icon-lnr-minus-circle-content;
  }
  .icon-lnr-paperclip:before {
    content: $icon-lnr-paperclip-content;
  }
  .icon-lnr-pencil:before {
    content: $icon-lnr-pencil-content;
  }
  .icon-lnr-plus-circle:before {
    content: $icon-lnr-plus-circle-content;
  }
  .icon-lnr-printer:before {
    content: $icon-lnr-printer-content;
  }
  .icon-lnr-question-circle:before {
    content: $icon-lnr-question-circle-content;
  }
  .icon-lnr-smartphone:before {
    content: $icon-lnr-smartphone-content;
  }
  .icon-lnr-sync:before {
    content: $icon-lnr-sync-content;
  }
  .icon-lnr-upload:before {
    content: $icon-lnr-upload-content;
  }
  .icon-lnr-user:before {
    content: $icon-lnr-user-content;
  }
  .icon-lnr-warning:before {
    content: $icon-lnr-warning-content;
  }
  .icon-loop:before {
    content: $icon-loop-content;
  }
  .icon-map-view:before {
    content: $icon-map-view-content;
  }
  .icon-menu-burger:before {
    content: $icon-menu-burger-content;
  }
  .icon-plus:before {
    content: $icon-plus-content;
  }
  .icon-qrcode:before {
    content: $icon-qrcode-content;
  }
  .icon-refund-ticket:before {
    content: $icon-refund-ticket-content;
  }
  .icon-scheme:before {
    content: $icon-scheme-content;
  }
  .icon-terminal:before {
    content: $icon-terminal-content;
  }
  .icon-ticket:before {
    content: $icon-ticket-content;
  }
  .icon-trash:before {
    content: $icon-trash-content;
  }
  .icon-upload-card:before {
    content: $icon-upload-card-content;
  }
  .icon-xlsx-file:before {
    content: $icon-xlsx-file-content;
  }
}
