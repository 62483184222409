.status-validator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: #b5b5b5;

  &--online {
    background-color: var(--mp-success);
  }

  &--offline {
    background-color: var(--mp-danger);
  }

  &--waiting {
    background-color: #AEA04B;
  }
}

.status-validator-color {
  &--online {
    color: var(--mp-success);
  }

  &--offline {
    color: var(--mp-danger);
  }

  &--waiting {
    color: #AEA04B;
  }
}

