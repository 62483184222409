.ng-bar-placeholder {
  height: 3px !important;
  top: 0;

  .ng-bar {
    background-color: var(--progress-bar-bg) !important;
  }

  .ng-meteor {
    box-shadow: var(--progress-bar-bg) 0 0 10px, var(--progress-bar-bg) 0 0 5px !important;
  }
}
