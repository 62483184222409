.sort {
  &__item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    padding: 5px 10px 5px 30px;
    cursor: move;

    .icon-drag {
      color: $gray-700;
      font-size: 2rem;
    }

    &:hover {
      background: rgba(68, 176, 173, 0.05);
    }
  }

  &__item--disabled,
  &__item--disabled:hover {
    cursor: default;
    background: transparent;
  }

  &__base {
    position: absolute;
    left: 15px;
  }
}
