.tr--disabled {
  background-color: #f5f5f5;
  opacity: 0.75;
  transition: opacity 0.4s ease;

  &:hover {
    opacity: 1;
  }
}

.table {
  td {
    .icon-trash {
      font-size: 20px;
      position: relative;
      top: 2px;
    }
  }
}

.th {
  &--10 {
    width: 10px;
  }

  &--16 {
    width: 16px;
  }

  &--24 {
    width: 24px;
  }

  &--32 {
    width: 32px;
  }

  &--55 {
    width: 55px;
  }

  &--60 {
    width: 60px;
  }

  &--100 {
    width: 100px;
  }

  &--120 {
    width: 120px;
  }

  &--150 {
    width: 150px;
  }

  &--170 {
    width: 170px;
  }

  &--200 {
    width: 200px;
  }

  &--250 {
    width: 200px;
  }

  &--300 {
    width: 300px;
  }

  &--id {
    width: 55px;
  }

  &--status {
    width: 60px;
  }

  &--name {
    min-width: 250px;
    word-break: break-all;
  }

  &--full-date {
    width: 250px;
  }

  &--date {
    width: 200px;
  }
}

tr.mat-row, tr.mat-footer-row {
  height: auto !important;
}
