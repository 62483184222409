.headline {
  min-height: 100px;

  @include media-breakpoint-down('sm') {
    min-height: 0;
  }

  h1 {
    word-break: break-word;
    @include media-breakpoint-down('sm') {
      font-size: 20px;
    }
  }

  &__lead {
    color: $gray-700;
  }
}
