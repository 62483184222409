.btn {
  &-primary {
    color: #fff;
    background-color: var(--button-bg) !important;
    border-color: var(--button-bg) !important;

    &:hover {
      background-color: var(--button-hover-bg) !important;
      border-color: var(--button-hover-bg) !important;
    }

    &:active {
      background-color: var(--button-hover-bg) !important;
      border-color: var(--button-hover-bg) !important;
    }
  }

  &-default,
  &-outline {
    color: #0C0C0C;
    background-color: transparent !important;
    border-color: #999 !important;

    &:hover {
      color: #000;
      background-color: transparent !important;
      border-color: #000 !important;
    }

    &:active {
      color: #000;
      box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
      background-color: transparent !important;
    }
  }

  &:disabled {
    border-color: var(--mp-gray-600) !important;
    background: var(--mp-gray-600) !important;
    color: var(--mp-white) !important;
  }

  /** For datepicker */
  &.calendar {
    width: 2.75rem;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=) !important;
    background-repeat: no-repeat;
    background-size: 23px;
    background-position: 50%;
    border-radius: 0 2px 2px 0;
  }
}
