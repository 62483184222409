.info-grid {
  &__row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  &__label {
    color: rgba(0,0,0,.7);
  }
}
