@import 'bootstrap-variables-override';
@import "bootstrap-modules";
@import "bootstrap-forms-override";
@import "bootstrap-buttons-override";


:root{
  --mp-body-bg: var(--background-color) !important;
  --mp-body-color: var(--text-color) !important;
  --mp-link-color: var(--link-color) !important;
}

.font-size-sm {
  font-size: $font-size-sm !important;
}

.bs-timepicker-field.form-control {
  width: 48px !important;
}

timepicker {
  .btn-link {
    border: none !important;
  }
}

timepicker.timepicker-sm {
  .form-control {
    min-height: calc(1.5em + 0.3rem + calc(1px* 2));
    padding: 0.15rem 0.5rem;
    font-size: 0.875rem;
    border-radius: var(--mp-border-radius-sm);
  }

  .btn {
    padding: 0.15rem 0.85rem;
    font-size: 0.875rem;
    border-radius: 20px;
  }
}

