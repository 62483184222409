.layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

.loading-content {
  pointer-events: none !important;
  animation: opacity-pulse 2s;
  animation-iteration-count: infinite;
}
