.table {
  @include media-breakpoint-down('md') {
    min-width: 600px;
  }

  @include media-breakpoint-down('sm') {
    min-width: 320px;
    min-height: 0;
  }

  &__container {
    overflow-x: auto;
    min-height: 320px;
  }

  thead tr th,
  tbody tr td {

    &:first-child {
      padding-left: 15px;

      @include media-breakpoint-up(sm) {
        padding-left: 25px;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 25px;
      }
    }

    &:last-child {
      padding-right: 15px;

      @include media-breakpoint-up(sm) {
        padding-right: 25px;
      }

      @include media-breakpoint-up(lg) {
        padding-right: 50px;
      }
    }
  }

  thead {
    tr {
      height: auto;

      th {
        font-weight: normal;
        font-size: $font-size-sm;
        line-height: 16px;
        color: $gray-600;
        padding: 0 7px 4px;
        border: none;

        .mat-sort-header-button {
          border-bottom: 1px dotted;
          @include link-color($gray-600);

          &:hover {
            @include link-color($gray-700);
          }
        }
      }
    }
  }

  tbody {
    border-top: 1px solid $gray-500;

    & + tbody{
      border-top: 1px solid $gray-500;
    }

    tr {
      border-bottom: 1px solid #e5e5e5;
      color: $input-focus-color;

      &:not(.tr--hover-off) {
        cursor: pointer;
      }

      &.tr--cursor-default {
        cursor: default !important;
      }

      @include media-breakpoint-up(lg) {
        border-bottom: none;
      }

      &:not(.tr--hover-off):hover {
        background-color: rgba(68, 176, 173, .05);
      }

      td {
        padding: 7px;
        border: none;
        max-width: 250px;

        &.td--multiline {
          line-height: 1.5rem;
          word-break: break-word;
        }
      }
    }
  }

  &--hideThead {
    thead {
      visibility: hidden;
    }

    tbody {
      border-top: none;
    }
  }
}

/** Material default style override */
.mat-mdc-header-row,
.mdc-data-table__row {
  height: auto !important;
}
