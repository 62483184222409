@use '@angular/material' as mat;

$my-typography-level: mat.define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 400,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);

$my-custom-typography-config: mat.define-typography-config(
  $body-1: $my-typography-level,
  $body-2: $my-typography-level,
);

$my-app-primary: mat.define-palette(mat.$grey-palette);
$my-app-accent: mat.define-palette(mat.$grey-palette, A200, A100, A400);
$my-app-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((
  typography: $my-custom-typography-config,
  color: (
    primary: $my-app-primary,
    accent: $my-app-accent,
    warn: $my-app-warn,
  ),
));

@include mat.core();

@include mat.strong-focus-indicators((
  border-width: 2px,
  border-color: blue,
));

@include mat.all-component-themes($my-theme);

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: #000 !important;
}

.mat-button-toggle.mat-button-toggle-sm {
  font-size: 14px;

  .mat-button-toggle-label-content {
    line-height: 26px;
  }
}
