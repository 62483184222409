@import "bootstrap-media-breakpoints-mixins";


form {
  max-width: 1200px;
}

.max-width-form {
  max-width: 900px;
}


.form-group {
  /** Backward compatibility for form-group */
  margin-bottom: 22px;

  label {
    color: rgba(0, 0, 0, .75);
    margin-bottom: 3px;
    font-size: .875rem;
  }

  .hint {
    font-size: 14px;
    color: rgba(0, 0, 0, .6);
  }

  &-disabled {
    opacity: .7;
    pointer-events: none;
  }

  input::-ms-reveal {
    display: none;
  }

  &-sak {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 10px;

    span {
      position: relative;
      top: -2px;
    }
  }
}

/** Remove is-valid style */
.form-control {
  outline: 0 !important;

  &.is-valid {
    color: $input-color;
    border-color: $input-border-color;

    &:focus {
      border-color: $input-focus-border-color;
    }
  }

  &:disabled {
    color: $input-disabled-color !important;
    border-color: $input-disabled-border-color !important;
    background: transparent;
  }

  &:not(.is-invalid):focus:hover {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }

  &--price {
    width: 75px;
  }

  &--phoneCode {
    width: 65px;
  }
}

.currency {
  font-size: 22px;
}

fieldset {
  border-left-width: 4px;
  border-left-style: solid;
  border-color: var(--brand-color);
}

.form-control__static-value {
  font-size: 1.2rem;
  display: block;
  font-weight: bold;
  font-family: var(--font-heading);
}
