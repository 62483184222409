.filter-box {
  display: flex;
  flex-wrap: wrap;

  ui-calendar-control {
    max-width: 300px;
  }

  &__dateWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    & > * {
      @media (max-width: 991px) {
        flex: 1 1 100%;
        padding: 0;
      }
    }
  }

  &__dateTime {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  & > * {
    flex: 1 1 auto;
    padding: 0 10px;

    @media (max-width: 991px) {
      flex: 1 1 100%;
      padding: 0;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
