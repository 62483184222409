// Position
.toast {
  &-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-top-center {
    top: 0;
    right: 0;
    width: 100%;
  }

  &-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
  }

  &-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
  }

  &-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
  }

  &-top-left {
    top: 12px;
    left: 12px;
  }

  &-top-right {
    top: 25px;
    right: 40px;
  }

  &-bottom-right {
    right: 12px;
    bottom: 12px;
  }

  &-bottom-left {
    bottom: 12px;
    left: 12px;
  }

  &-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;

    .ngx-toastr {
      position: relative;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      padding: 20px;
      margin-bottom: 15px;
      min-width: 300px;
      pointer-events: auto;
      border-radius: 4px;

      .toast-title {
        font-weight: bold;
      }

      .toast-message {
        font-weight: 400;
        line-height: 1rem;
        word-wrap: break-word;
      }

      .toast-message a:hover {
        text-decoration: underline;
      }

      .toast-close-button {
        right: 15px;
        top: 15px;
        cursor: pointer;
        position: absolute;
        background: none;
        border: none;
        outline: none;
        font-size: 0;
        padding: 0;

        @extend .icon;
        @extend .icon-close;

        &:before {
          font-size: 1rem;
        }

        &:hover,
        &:focus {
          opacity: 0.8;
        }
      }

      .toast-progress {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        background-color: #000000;
        opacity: 0.3;
      }
    }

    .toast:hover {

      opacity: 1;
      cursor: pointer;
    }
  }

  &-success {
    background: #fff;
    border: 2px solid $success;
  }

  &-error {
    background: #fff;
    border: 2px solid $danger;
  }

  &-info {
    border: 2px solid gray;
  }

  &-warning {
    border: 2px solid $warning;
  }
}


.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
