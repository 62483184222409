.tree-children.tree-children-no-padding {
  padding-left: 0
}

.node-drop-slot {
  display: block;
  height: 1px;
}

.tree-children {
  padding-left: 20px;
  overflow: hidden;

  tree-node:last-child .node-drop-slot {
    height: 0;
  }
}

.node-drop-slot.is-dragging-over {
  background: #ddffee;
  height: 20px;
  border: 2px dotted #888;
}

.toggle-children-wrapper-expanded .toggle-children {
  transform: rotate(90deg)
}

.toggle-children-wrapper-collapsed .toggle-children {
  transform: rotate(0);
}

.toggle-children-wrapper {
  padding: 0;
  @extend .icon;
  @extend .icon-expand;
  z-index: 1;

  &:before {
    position: absolute;
    font-size: 10px;
    top: 15px;
    left: 13px;
    transition: transform .4s;
  }

  &-expanded {
    &:before {
      transform: rotate(180deg);
    }
  }
}

/* tslint:disable */
.toggle-children {
  display: inline-block;
  position: relative;
  top: 1px;
}

.node-content-wrapper {
  display: inline-block;
  padding: 0;
  width: 100%;
}

.node-wrapper {
  display: flex;
  align-items: flex-start;
}

.node-content-wrapper.is-dragging-over-disabled {
  opacity: 0.5
}

tree-viewport {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  overflow: auto;
  display: block;
}

.tree-children {
  padding-left: 50px
}

.empty-tree-drop-slot .node-drop-slot {
  height: 20px;
  min-width: 100px
}

.angular-tree-component {
  width: 100%;
  position: relative;
  display: inline-block;
  //cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* non-prefixed version, currently not supported by any browser */
  overflow: hidden;
}

tree-root .angular-tree-component-rtl {
  direction: rtl;
}

tree-root .angular-tree-component-rtl .toggle-children-wrapper-collapsed .toggle-children {
  transform: rotate(180deg) !important;
}

tree-root .angular-tree-component-rtl .tree-children {
  padding-right: 20px;
  padding-left: 0;
}

tree-node-checkbox {
  padding: 1px;
}
