.bg-brand {
  background: var(--brand-color) !important;
}

.bg-gray-light {
  background: var(--mp-gray-200) !important;
}

.right-0 {
  right: 0 !important;
}

.top-0 {
  top: 0 !important;
}

.min-h-0 {
  min-height: 0 !important;
}
