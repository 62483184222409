.f-tree {
  background-color: var(--mp-gray-200);
  position: relative;
  transition: bgColor .4s ease-in-out;

  &:hover {
    background-color: var(--mp-gray-300);
  }

  &--hovered {
    cursor: pointer;
  }

  &--selected {
    background: var(--selected-element-bg) !important;
    cursor: default !important;
  }

  &__title {
    font-size: 20px;
    line-height: 20px;
    word-break: break-all;

    @include media-breakpoint-down('sm') {
      font-size: 14px;
      line-height: 16px;
    }

    &--fLevel {
      color: #23527c;
    }
  }

  &__tags {
    opacity: .5;
    position: relative;
    top: 10px;
  }

  &__icon {
    position: absolute;
    right: -3px;
    top: 12px;
    opacity: .5;
  }

  &__id {
    min-width: 32px;
    text-align: right;
  }

  &__id,
  &__amount {
    font-size: 12px;
    color: rgba(0, 0, 0, .6);
    position: relative;
  }

  .icon-close {
    font-size: 12px;
    display: inline-block;
    position: relative;
    transform: rotate(45deg);
    zoom: 0;
  }

  .i--disabled {
    pointer-events: none;
    cursor: default !important;
    opacity: .4;
  }
}
