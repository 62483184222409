$font-family-sans-serif: var(--font-text), -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

$headings-font-family: var(--font-heading);

$prefix: 'mp-';

/** Gray palette override */
$gray-200: #F6F5F3;
$gray-300: #dee2e6;
$gray-500: #E5E5E5;
$gray-600: #999;
$gray-700: #656565;
$gray-800: #404040;

/** Headings */
$headings-font-weight: 700 !default;
$h1-font-size: 2.25rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;

/** Spacers */
$spacers: (
  0: 0,
  1: 5px,
  2: 10px,
  3: 15px,
  4: 20px,
  5: 25px,
  6: 30px,
  7: 35px,
  8: 40px,
  9: 45px,
  10: 50px,
  11: 55px,
  12: 60px,
  13: 65px,
  14: 70px,
  15: 75px,
  16: 80px,
  17: 85px,
  18: 90px,
  19: 95px,
  20: 100px,
);

/** Buttons sizes */
$btn-border-radius: 36px;
$btn-border-radius-lg: 36px;
$btn-transition: all .4s ease;
$btn-disabled-opacity: .4;
$btn-padding-y-lg: .3125rem;
$btn-padding-x-lg: 1.4375rem;
$btn-border-radius-sm: 20px;
$btn-padding-x-sm: .85rem;
$input-btn-padding-y-sm: 0.15rem;

/** Borders */
$border-width: 1px;
$border-color: $gray-300;

/** Forms override */
$input-height: 42px;
$input-color: #404040;
$input-focus-color: #0C0C0C;
$input-placeholder-color: #999;
$input-border-width: 1px;
$input-border-color: $gray-600;
$input-border-radius: 3px;
$input-box-shadow: none;
$input-focus-width: 1px;
$input-focus-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
$input-focus-border-color: #007eff;
$input-hover-border-color: $gray-700;
$input-disabled-bg: #999;
$input-disabled-border-color: $gray-500;
$input-disabled-color: $gray-600;
$form-group-margin-bottom: 15px;
$form-feedback-valid-color: #248E13;
$form-feedback-invalid-color: #B91E14;
$enable-validation-icons: false;


/** Checkbox / Radio **/
$form-check-input-checked-bg-color: var(--brand-color);
