.tariff {
  &-row {
    padding: 5px 40px;
    min-height: 54px;

    &--link {
      cursor: pointer;
    }

    @include media-breakpoint-down('sm') {
      padding: 10px 0;
    }

    &:hover {
      background-color: rgba(68, 176, 173, .05);
    }

    &--default {
      background: #fbfbfb;
    }

    &--th {
      padding: 0 40px;
      min-height: 0;
      background: transparent !important;

      @include media-breakpoint-down('sm') {
        padding: 0;
      }
    }
  }

  &__index {
    flex: 0 0 1.5em;
    color: #cccccc;
  }

  &__drag {
    font-size: 2.6em;
    color: #cccccc;
    cursor: move;
    flex: 0;
    margin-left: 5px;
    min-width: 42px;
    line-height: 1;

    i {
      position: relative;
      top: 3px;
    }
  }

  &__active {
    flex: 0 1 2em;
  }

  &__name {
    flex: 1;
    padding-right: .3em;

    @include media-breakpoint-down('sm') {
      display: block;
      width: 100%;
    }

    span {
      font-size: 1.2rem;

      @include media-breakpoint-down('sm') {
        font-size: .9rem;
      }
    }
  }

  &__info {
    flex: 0 1 30px;

    .icon-info {
      font-size: 1.2rem;
      position: relative;
      top: 4px;
    }
  }

  &__copy,
  &__remove {
    flex: 0 1 30px;

    .icon-trash {
      font-size: 1.2rem;
      position: relative;
      top: 1px;
    }
  }


  &__versionNote {
    flex: 0 1 200px;
    padding-right: 15px;

    @include media-breakpoint-up('xl') {
      flex: 0 1 400px;
    }
  }

  &__priceType {
    flex: 0 1 200px;
  }

  &__price {
    flex: 0 1 100px;
    padding-right: 20px;
  }
}
