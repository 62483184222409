@import './mixins/mixins.scss';

/* Base links */
a {
  border-bottom: 1px solid;
  transition: opacity 0.5s, border-bottom 0.5s;
  @include link-color($link-color, true);
  text-decoration: none !important;
  cursor: pointer;
  opacity: 1;

  > img {
    display: inline-block;
  }

  &.hovered {
    transition: opacity 1s;
    text-decoration: none;
    opacity: .6;
  }
}

/* Active */
a:active {
  @include link-color($link-active-color, true);
}

/* Extend class links */
.a {
  @extend a;
  cursor: pointer;

  &--border-off {
    border-bottom: none;
  }

  &--white {
    @include link-color(#fff, true);

    &:hover {
      @include link-color(rgba(255, 255, 255, .7), true)
    }
  }

  &--black {
    @include link-color(#000, true);
  }

  &--gray {
    @include link-color(rgba(0,0,0,.6), true);
  }

  &--danger {
    @include link-color(var(--mp-danger), true);
  }
}

/* Pseudo links */
.pseudo {
  cursor: pointer;
  border-bottom: 1px dotted;
  transition: color 0.5s, border-bottom 0.5s;
  @include link-color($link-color);
  text-decoration: none;
  user-select: none;

  &--disabled,
  &.disabled,
  &.disabled:hover {
    opacity: .4;
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    transition: color 0s, border-bottom 0s;
    text-decoration: none;
    @include link-color($link-hover-color, $important: true);
  }

  &.active {
    @include link-color($link-hover-color, $important: true);
    border-color: transparent !important;
  }

  &.icon {
    border: none;
  }

  &--gray {
    @include link-color(rgba(0,0,0,.6));
  }

  &--white {
    @include link-color(#fff);

    &:hover {
      @include link-color(rgba(255, 255, 255, .7), $important: true)
    }
  }

  &--black {
    @include link-color(#000);

    &:hover {
      @include link-color(rgba(0, 0, 0, .7), $important: true)
    }
  }

  &--danger {
    @include link-color(var(--mp-danger));
  }

  &--success {
    @include link-color(var(--mp-success));
  }

  &--selected {
    cursor: default !important;

    &:hover {
      color: inherit !important;
    }
  }
}

%pseudo--gray {
  &--gray {
    @include link-color(rgba(0,0,0,.6));
  }
}

.pseudo--border-off {
  border-bottom: none;
}

.pseudo--sort {
  position: relative;

  &:after {
    position: absolute;
    right: -10px;
  }

  &.pseudo--sort-asc:after {
    content: '↑';
  }

  &.pseudo--sort-desc:after {
    content: '↓';
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  a,
  .a,
  .pseudo {
    border-bottom-width: 1.5px;
  }
}
