// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path,  $weight: null, $style: null, $exts: eot woff ttf) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: swap;
  }
}


$fonts-icomoon-path: '/assets/fonts/icons/';
$fonts-path: '/assets/fonts/';

@font-face {
  font-family: 'icomoon';
  src:url($fonts-icomoon-path + 'icomoon.eot');
  src:url($fonts-icomoon-path + 'icomoon.eot?#iefix') format('embedded-opentype'),
  url($fonts-icomoon-path + 'icomoon.svg#IcoMoon') format('svg'),
  url($fonts-icomoon-path + 'icomoon.woff') format('woff'),
  url($fonts-icomoon-path + 'icomoon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:wght@300;400,700&display=swap');

@include font-face("Formular", $fonts-path + 'Formular', 400);
@include font-face("Formular", $fonts-path + 'Formular-Light', 300);
@include font-face("Formular", $fonts-path + 'Formular-Bold', 700);

@font-face {
  font-family: 'SnT';
  src: url($fonts-path + 'SnT-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SnT';
  src: url($fonts-path + 'SnT-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


//noinspection CssNoGenericFontName
.icon,
[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media print {
    display: none !important;
  }
}

@include icon-classes();

